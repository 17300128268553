import React, { CSSProperties } from 'react'

import './TextComponent.scss'

export type ITypeTextComponent = 'h1' | 'h2' | 'h3' | 'subtitle' | 'button' | 'caption' | 'body' | 'text' | 'label'

interface ITextComponentProps {
  type?: ITypeTextComponent
  className?: string
  style?: CSSProperties
  children?: any
  as?: 'p' | 'span'
  onClick?: () => any
}

const TextComponent: React.FC<ITextComponentProps> = (props) => {
  
  const textType: ITypeTextComponent = props.type ? props.type : 'text'
  
  return(
    props?.as === 'p' ?
      <p
        className={`text_component-layout ${textType} ${props.className ? props.className : ''}`}
        style={props.style}
        onClick={props.onClick}
      >
        {props.children}
      </p>
    : props?.as === 'span' ?
      <span
        className={`text_component-layout ${textType} ${props.className ? props.className : ''}`}
        style={props.style}
        onClick={props.onClick}
      >
        {props.children}
      </span>
    :
      <p
        className={`text_component-layout ${textType} ${props.className ? props.className : ''}`}
        style={props.style}
        onClick={props.onClick}
      >
        {props.children}
      </p>
  );
}

export default TextComponent;
