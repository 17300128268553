import React from 'react';
import Energy from 'assets/images/landing/Products/Energy.png';
import Academy from 'assets/images/landing/Products/Academy.png';
import AI from 'assets/images/landing/Products/AI.png';
import './ProductsCategories.scss';

const ProductsCategories: React.FC = () => {
  const handleCardClick = (url: string) => {
    window.location.href = url;
  };

  return (
    <div className="products-categories">
      <h2>Nuestros productos</h2>
      <div className="categories-container">
        <div className="category-card" onClick={() => handleCardClick('https://academy.vlesim.com/')}>
          <img src={Energy} alt="Energy" />
          <h3>VLESIM Energy</h3>
          <p>Plataforma para la integración de datos en sistemas de energía</p>
          <button>Ver más</button>
        </div>
        <div className="category-card" onClick={() => handleCardClick('https://academy.vlesim.com/')}>
          <img src={Academy} alt="Academy" />
          <h3>VLESIM Academy</h3>
          <p>Plataforma en la nube de boratorios virtuales y remotos </p>
          <button>Ver más</button>
        </div>
        {/* <div className="category-card" onClick={() => handleCardClick('https://www.vlesim.com')}>
          <img src={AI} alt="AI" />
          <h3>AI</h3>
          <p>Soluciones personalizadas para resolver las necesidades de tu empresa.</p>
        </div> */}
      </div>
    </div>
  );
};

export default ProductsCategories;