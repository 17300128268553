// @import dependencies
import TitlePageComponent from '../../commons/TitlePage/TitlePageComponent';
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import ColombiaProductiva from 'assets/images/landing/logos/colombia_productiva.png'
import SenaInova from 'assets/images/landing/logos/senaiinova.png'
import SenaIm from 'assets/images/landing/logos/senaim.png'
import Ecopetrol from 'assets/images/landing/logos/Ecopetrol.png'
// @end assets

// @import styles
import './SeeClients.scss'
import shortId from 'shortid';
// @end styles

interface ISeeClientsProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const SeeClients: React.FC<ISeeClientsProps> = (props) => {

    const logos: {id: string, image: any}[] = [
        {
            id: shortId.generate(),
            image: Ecopetrol
        },
        {
            id: shortId.generate(),
            image: SenaIm
        },
        {
            id: shortId.generate(),
            image: ColombiaProductiva
        },
        
    ]

    return(
        <div
            className={`footer_landing-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <h2>
                Nuestros Clientes
            </h2>
            <div className="container-logos">
                {logos.map((_l) => (
                    <img 
                        key={_l.id}
                        src={_l.image} 
                        alt="" 
                    />
                ))}
            </div>
        </div>
    );
}

export default SeeClients;