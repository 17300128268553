import React, { CSSProperties, useState } from "react";
import ButtonComponent from "../../commons/Button/ButtonComponent";
import FooterLanding from "../FooterLanding/FooterLanding"; // Asegúrate de importar tu FooterLanding
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiMenu } from "react-icons/bi";
import useWindowWidth from "../../../hooks/useWindowWidth/useWindowWidth";
import vlesimIcon from "assets/images/logoVlesim1.png";
import "./HeaderLandingPage.scss";
import TextComponent from "../../commons/Text/TextComponent";

interface IHeaderLandingPageProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const HeaderLandingPage: React.FC<IHeaderLandingPageProps> = (props) => {
  const [open, updateOpen] = useState<boolean>(false);
  const { windowWidth } = useWindowWidth();

  const handleScroll = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      updateOpen(false); // Cierra el menú en dispositivos móviles
    }
  };

  return (
    <div
      className={`header_landing_page-layout ${
        props.className ? props.className : ""
      }`}
      style={props.style}
      id={props.id}
    >
      <img src={vlesimIcon} alt="Vlesim Logo" className="logo-desktop" />
      {windowWidth <= 960 && !open && (
        <div className="container-menu-movil" onClick={() => updateOpen(true)}>
          <BiMenu />
        </div>
      )}
      <div className={`container-actions-header ${open ? "open" : ""}`}>
        {windowWidth <= 960 && open && (
          <>
            <div
              className="close-icon-menu-movil"
              onClick={() => updateOpen(false)}
            >
              <AiOutlineCloseCircle />
            </div>
            {/* Aquí renderizamos el FooterLanding en lugar del menú habitual */}
            <FooterLanding />
          </>
        )}
        {windowWidth > 960 && (
          <>
            <TextComponent
              style={{
                marginRight: "15px",
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => handleScroll("services-section")}
            >
              Servicios
            </TextComponent>
            <TextComponent
              style={{
                marginRight: "15px",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => handleScroll("products-section")}
            >
              Productos
            </TextComponent>
            <TextComponent
              style={{
                marginRight: "15px",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => handleScroll("about")}
            >
              Nosotros
            </TextComponent>
            {/* <ButtonComponent
                            variant='primary2'
                            style={{
                                color: 'black',
                                cursor: 'pointer'
                            }}
                            onClick={() => handleScroll('contact')}
                        >
                            Contáctenos
                        </ButtonComponent> */}
            <a
              href="https://wa.me/573163747465"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              <ButtonComponent
                variant="primary2"
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Contáctenos
              </ButtonComponent>
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderLandingPage;
