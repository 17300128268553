import React, { CSSProperties, MouseEvent } from 'react';
import './ButtonComponent.scss';

export type IVariantButtonType = 'primary' | 'secondary' | 'accent' | 'primary2' | 'primaryInverter';

export interface ButtonComponentProps {
    children?: any;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    variant?: IVariantButtonType;
    loading?: boolean;
    iconColor?: string;
    onClick?: () => void;
}

interface IIconButtonComponentProps {
    children?: any;
}

interface ISubComponentsButton {
    Icon: React.FC<IIconButtonComponentProps>;
}

const ButtonComponent: React.FC<ButtonComponentProps> & ISubComponentsButton = (props) => {

    const handleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        if (props.onClick) {
            props.onClick();
        }
    };

    const getClassFromVariant = () => {
        if (!props.variant) return '';
        switch (props.variant) {
            case 'primary':
                return 'btn-primary';
            case 'primary2':
                return 'btn-primary2';
            case 'secondary':
                return 'btn-secondary';
            case 'accent':
                return 'btn-accent';
            case 'primaryInverter':
                return 'primary-inverter';
            default:
                return '';
        }
    };

    return (
        <button
            className={`btn btn-custom ${props.className ? props.className : ''} ${getClassFromVariant()}`}
            onClick={handleClick}
            style={props.style}
            disabled={props.disabled || props.loading}
        >
            {props.loading ?
                <div className="spinner-border spinner-loading-button" role="status"></div>
                : <div></div>
            }
            {props.children}
        </button>
    );
};

const Icon: React.FC<IIconButtonComponentProps> = (props) => {
    return (
        <div className="container-icon-button">
            {props.children}
        </div>
    );
};

ButtonComponent.Icon = Icon;

export default ButtonComponent;