import React, { useEffect, useState } from 'react';

/**
 * @INFO Obtener el ancho de la ventana
 * @returns
 */
const useWindowWidth = () => {

  const [windowWidth, updateWindowWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  const handleResize = (e: any) => {
    updateWindowWidth(window.innerWidth)
  };

  return {
    windowWidth
  }
}

export default useWindowWidth
