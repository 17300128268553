import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "@components/commons/Button/ButtonComponent";
import LandingLayout from "@screens/LandingLayout/LandingLayout";
import DashboardLanding from "@screens/DashboardLanding/DashboardLanding";
import ProductsCategories from "@screens/ProductsCategories/ProductsCategories";
import { useTranslation } from "react-i18next";
import "./HomeLandingPage.scss";
import SeeClients from "../SeeClients/SeeClients";

// Define la interfaz IHomeLandingPageProps aquí
interface IHomeLandingPageProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  history?: any; // Considera tipar correctamente usando History de react-router-dom
}

const HomeLandingPage: React.FC<IHomeLandingPageProps> = (props) => {
  const { t } = useTranslation();

  return (
    <LandingLayout>
      <div
        className={`home_landing_page-layout ${props.className || ""}`}
        style={props.style}
        id={props.id}
      >
        {/* Texto principal */}
        <div className="container-text">
          <div className="subtitle">Nos adaptamos a cada cliente</div>
          <div className="title">
            Ofrecemos{" "}
            <span className="highlight-green">productos tecnológicos,</span>
          </div>
          <div className="title">
            servicios de{" "}
            <span className="highlight-blue">consultoría y capacitación.</span>
          </div>
          <div className="button-container">
            <a
              href="https://wa.me/573163747465"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              <ButtonComponent
                variant="primary2"
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Contáctenos
              </ButtonComponent>
            </a>
          </div>
        </div>

        {/* Contenido principal */}
        <div className="container-content-page">
          <div className="container-text">
            <div id="services-section" className="title">
              Nuestros Servicios
            </div>
          </div>
          <div className="container-services row">
            <DashboardLanding />
          </div>
        </div>

        {/* Categorías de productos */}
        <div
          id="products-section"
          className="container-categories-products row"
        >
          <ProductsCategories />
        </div>

        {/* Clientes */}
        <div className="see-clients">
          <SeeClients />
        </div>

        {/* Call to Action */}
        <div className="container-text">
          <div className="subtitle">¿Tienes un proyecto en mente?</div>
          <div className="title">
            Desarrollémoslo <span className="highlight-blue">juntos.</span>
          </div>
          <div className="button-container text-center">
            <a
              href="https://wa.me/573163747465"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              <ButtonComponent
                variant="primary2"
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Contáctenos
              </ButtonComponent>
            </a>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default HomeLandingPage;
