import React from 'react';
import vlesimIcon from '../../../assets/images/logoVlesim1.png';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Asegúrate de que esto esté importado
import './FooterLanding.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

const FooterLanding: React.FC = () => {

  const handleScroll = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer-landing">
      <div className="footer-content">
        <div className="footer-section">
          <h4
            onClick={() => handleScroll('services-section')}
            style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          >
            Servicios
          </h4>
          <ul>
            <li>Soluciones de energía</li>
            <li>Software & Analítica</li>
            <li>Eficiencia Energética</li>
            <li>Automatización</li>
          </ul>
        </div>
        <div className="footer-section">
          <h4
            onClick={() => handleScroll('products-section')}
            style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          >
            Productos
          </h4>
          <ul>
            <li>VLESIM Energy</li>
            <li>VLESIM Academy</li>
            <li>VLESIM AI</li>
          </ul>
        </div>
        <div className="footer-section">
          <h4
            onClick={() => handleScroll('about')}
            style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          >
            Nosotros
          </h4>
          <ul>
            <li>Cel: +57 3163747465</li>
            <li>support@vlesim.com</li>
            <li>Cra 13# 13C -50 Pasto, Nariño</li>
          </ul>
        </div>
      </div>
      <div className="footer-powered">
        <img src={vlesimIcon} alt="Vlesim Logo" className="footer-logo" />
        <div className="social-icons">
  {/* WhatsApp */}
  <a href="https://wa.me/+57 3163747465" target="_blank" rel="noopener noreferrer" className="icon-link">
    <i className="fab fa-whatsapp"></i>
  </a>

  {/* Email */}
  <a href="mailto:support@vlesim.com" className="icon-link">
    <i className="fa-solid fa-envelope"></i>
  </a>

  {/* LinkedIn (si necesitas un enlace específico) */}
  <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" className="icon-link">
    <i className="fab fa-linkedin"></i>
  </a>

  {/* YouTube */}
  <a href="https://www.youtube.com/@vlesim9553" target="_blank" rel="noopener noreferrer" className="icon-link">
    <i className="fab fa-youtube"></i>
  </a>
</div>


        Power by VLESIM Dev 💚
      </div>
    </footer>
  );
};

export default FooterLanding;
