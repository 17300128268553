import React, { useState } from "react";
import vlesimBN from "../../../assets/images/landing/logos/vlesim_bn.png";
import scope from "../../../assets/images/landing/logos/icon-scope.png";
import money from "../../../assets/images/landing/logos/icon-money.png";
import renovable from "../../../assets/images/landing/Services/renovable.jpeg";
import sustainability from "../../../assets/images/landing/logos/icon-healt.png";
import data from "../../../assets/images/landing/Services/analytics.png";
import web from "../../../assets/images/landing/Services/web.png";
import save from "../../../assets/images/landing/Services/save.png";
import iot from "../../../assets/images/landing/Services/iot.webp";
import mr from "../../../assets/icons/Renovables/mr.png";
import solar from "../../../assets/icons/Renovables/solar.png";
import battery from "../../../assets/icons/Renovables/battery.png";
import turbine from "../../../assets/icons/Renovables/turbine.png";
import meter from "../../../assets/icons/Renovables/meter.png";
import dev from "../../../assets/icons/Dev/dev.png";
import analytics from "../../../assets/icons/Dev/analytics.png";
import comunications from "../../../assets/icons/Dev/comunications.png";
import ia from "../../../assets/icons/Dev/ia.png";
import calidad from "../../../assets/icons/Eficiencia/calidad.png";
import motor from "../../../assets/icons/Eficiencia/motor.png";
import eficiencia from "../../../assets/icons/Eficiencia/eficiencia.png";
import racional from "../../../assets/icons/Eficiencia/racional.png";
import robot from "../../../assets/icons/Automation/robot.png";
import scada from "../../../assets/icons/Automation/scada.png";
import domotica from "../../../assets/icons/Automation/domotica.png";
import automation from "../../../assets/icons/Automation/automation.png";
import ButtonComponent from "@components/commons/Button/ButtonComponent";


import { MdClose } from "react-icons/md"; // Icono de cierre
import "./DashboardLanding.scss";
interface PopupContent {
  title: string;
  description: string;
  items?: { icon: string; text: string }[];
}

const DashboardLanding = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState<PopupContent>({
    title: "",
    description: "",
    items: [],
  });

  const handleCardClick = (
    title: string,
    description: string,
    items: { icon: string; text: string }[] = []
  ) => {
    setPopupContent({ title, description, items });
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="services">
      {/* Beneficios */}
      <div className="benefices-solutions-card">
        <div className="benefices-solutions-card-header">
          <img src={vlesimBN} alt="VLESIM Logo" className="main-logo" />
          <h2>Optimiza tu negocio con VLESIM</h2>
        </div>
        <h1>Lleva tu empresa a otro nivel</h1>
        <p>
          La implementación de nuestras soluciones benefician el rendimiento de
          tu empresa:
        </p>
        <div className="benefices-solutions-card-items">
          <ul className="features">
            <li>
              <img src={scope} alt="Microscope Icon" />
              <p>Integramos tecnología para mejorar y agilizar procesos</p>
            </li>
            <li>
              <img src={money} alt="Money Icon" />
              <p>Reducimos tus costos y tiempos de producción</p>
            </li>
            <li>
              <img src={sustainability} alt="Sustainability Icon" />
              <p>
                Mejoramos tu sostenibilidad y eficiencia energética. Produce
                cuidando el ambiente.
              </p>
            </li>
          </ul>
        </div>
      </div>

      {/* dashboard */}
      <div className="dashboard">
        <div className="dashboard-colum1">
          <div
            className="card-renewable"
            onClick={() =>
              handleCardClick(
                "Sistemas de energía",
                "Estudios de sistemas eléctricos de potencia y diseño de soluciones de energía renovale que aportan a la reducción de la facturación de energía, la independencia energética y la conservación del medio ambiente.",
                [
                  {
                    icon: mr,
                    text: "Diseño de microrredes con medición inteligente e integración de fuentes no convencionales de energía",
                  },
                  {
                    icon: meter,
                    text: "Infraestrucura de medición inteligente en redes de transmisión y distribución de energía eléctrica.",
                  },
                  {
                    icon: solar,
                    text: "Soluciones de energía fotovoltaica conectadas a la red princial y con soluciones de almacenamiento de energía basadas en baterías de ion litio para zonas no interconectadas.",
                  },
                  {
                    icon: turbine,
                    text: "Sistemas de generación a pequeña escala o microcentrales hidroeléctricas.",
                  },
                ]
              )
            }
          >
            <div className="renewable-image">
              <img src={renovable} alt="Renewable Energy" />
            </div>
            <div className="renewable-text">
              <h3>Soluciones de energía</h3>
              <h4>
                Consultoría especializada en sistemas de energía, microrredes y
                sistemas de generación.
              </h4>
              <button onClick={closePopup}>ver más</button>
            </div>
          </div>
          <div
            className="card-analytics"
            onClick={() =>
              handleCardClick(
                "Software & Analítica",
                "Ofrecemos soluciones personalizadas de desarrollo de software y analítica de datos que transforman la información en el activo más valioso de su compañia",
                [
                    {
                        icon: dev,
                        text: "Desarrollo de soluciones de software end to end para la gestión energética y la automatización de procesos."
                    },
                    {
                        icon: analytics,
                        text: "Analítica de datos, modelado y visualización para apoyar decisiones informadas."
                    },
                    {
                        icon: comunications,
                        text: "Integración de diversas aplicaciones, sistemas y protocolos de comunicación."
                    },
                    {
                        icon: ia,
                        text: "Desarrollo de modelos de inteligencia artificial y machine learning adaptados a las necesidades de tu negocio."
                    }
                ]
            )
            }
          >
            <h3>Software & Análitica</h3>
            <div>
              <img src={web} alt="" />
              <h4>Desarrollo de software multiplataforma a la medida</h4>
            </div>
            <button>Ver más</button>
          </div>
        </div>
        <div className="dashboard-colum2">
          <div
            className="card-efficiency"
            onClick={() =>
              handleCardClick(
                "Eficiencia Energética",
                "Ofrecemos soluciones especializadas para optimizar el consumo de energía eléctrica y reducir los costos de facturación. ",
                [
                    {
                        icon: calidad,
                        text: "Estudios de calidad de la potencia y calidad de la energía."
                    },
                    {
                        icon: eficiencia,
                        text: "Estudios de eficiencia energética y auditorías energéticas."
                    },
                    {
                        icon: motor,
                        text: "Compensación de energía reactiva y compensadores activos de armónicos."
                    },
                    {
                        icon: racional,
                        text: "Balance de carga y uso racional de la energía."
                    }
                ]
            )
            }
          >
            <h3>Eficiencia Energética</h3>
            <div>
              <img src={save} alt="" />
              <h4>Optimiza su consumo de energía eléctrica y obtenga ahorros en el costo de facuración </h4>
            </div>
            {/* <p>Predice Cambios</p> */}
            <button>Ver más</button>
          </div>

          <div
            className="card-home-automation"
            onClick={() =>
              handleCardClick(
                "Automatización",
                "Ofrecemos soluciones de automatización para optimizar procesos industriales y comerciales, mejorando la eficiencia y reduciendo costos operativos.",
                [
                    {
                        icon: robot,
                        text: "Robots de software para la automatización de procesos industriales y comerciales."
                    },
                    {
                        icon: domotica,
                        text: "Integración con sistemas de domótica e inmótica para un control total del entorno."
                    },
                    {
                        icon: scada,
                        text: "Desarrollo e implementación de sistemas SCADA para supervisión y control."
                    },
                    {
                        icon: automation,
                        text: "Soluciones personalizadas de automatización para optimizar la producción y la eficiencia energética."
                    }
                ]
            )
            }
          >
            <div className="card-home-automation-image">
              <img src={iot} alt="iot" />
            </div>
            <div className="card-home-automation-text">
              <h3>Automatización</h3>
              <h4>
                Servicios de domótica, inmótica, automatización industrial y
                robots de software.
              </h4>
              <button onClick={closePopup}>ver más</button>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <MdClose className="popup-close-icon" onClick={closePopup} />{" "}
            {/* Icono de cierre */}
            <div className="popup-descriptions-card">
              <div className="popup-descriptions-card-header">
                <img src={vlesimBN} alt="VLESIM Logo" className="main-logo" />
              </div>
              <h1>{popupContent.title}</h1>
              <p>{popupContent.description}</p>
              <div className="popup-descriptions-card-items">
                <ul className="popup-descriptions-card-features">
                  {popupContent.items?.map((item, index) => (
                    <li key={index}>
                      <img src={item.icon} alt="" />
                      <p>{item.text}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <a
              href="https://wa.me/573163747465"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              <ButtonComponent
                variant="primary2"
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Contáctenos
              </ButtonComponent>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardLanding;
