import React from 'react';
import './App.scss';
import HomeLandingPage from '@components/screens/HomeLandingPage/HomeLandingPage';

const App: React.FC = () => {
    return (
        <div>
            <HomeLandingPage />
        </div>
    );
};

export default App;
