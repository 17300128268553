import React, { CSSProperties } from 'react';
import FooterLanding from '../FooterLanding/FooterLanding';
import HeaderLandingPage from '../HeaderLandingPage/HeaderLandingPage';
import './LandingLayout.scss';

interface ILandingLayoutProps {
    children?: React.ReactNode; // Se recomienda usar React.ReactNode para tipar children
    className?: string;
    style?: CSSProperties;
    id?: string;
    hideFooter?: boolean;
}

const LandingLayout: React.FC<ILandingLayoutProps> = (props) => {
    return (
        <div
            className={`landing_layout-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div className='header-landing-page'>
                <HeaderLandingPage />
            </div>
            <main>
                {props.children}
            </main>
            <div id='about' className='footer-landing'>
            <FooterLanding />
            </div>

            
        </div>
    );
}

export default LandingLayout;
